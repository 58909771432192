<template>
  <tbody class="divide-y divide-bb-neutral-50 overflow-y-scroll">
    <!--Empty Table-->
    <tr v-if="tableRows.length === 0">
      <td colspan="13">
        <div class="mx-auto my-40 flex flex-col items-center justify-center">
          <img
            class="w-24 h-auth"
            src="/content/images/illustrations/binoculars.png"
            alt="dashboard"
          />
          <p class="my-2 w-100 text-center">
            No infringements detected. Keep monitoring your competitors to stay ahead.
          </p>
        </div>
      </td>
    </tr>
    <tr
      v-for="(item, index) in tableRows"
      v-else
      :key="index"
      class="whitespace-no-wrap font-normal w-full text-sm h-90px cursor-pointer"
      :class="hoveredRow === index ? 'bg-bb-background-purple-0' : ''"
      @click="$emit('show-more', item)"
      @mouseenter="hoveredRow = index"
      @mouseleave="hoveredRow = null"
    >
      <td
        class="whitespace-no-wrap sticky left-0"
        :class="hoveredRow === index ? 'bg-bb-background-purple-0' : 'bg-white'"
      >
        <div
          class="flex justify-center items-center gap-4 w-auto h-90px px-4"
          :class="item?.isNew ?? false ? 'gradient-border' : ''"
        >
          <div class="w-6">
            <img
              :src="`https://www.google.com/s2/favicons?domain=${item.competitorUrl}`"
              class="h-6 w-6"
            />
          </div>
          <div class="w-full">
            <p class="text-base font-medium text-bb-text-headers">{{ item.competitorName }}</p>
            <a
              :href="item.competitorUrl"
              target="_blank"
              @click.stop
            >
              <p class="text-sm font-normal text-bb-neutral-gray hover:text-bb-brand-purple">
                {{ item.competitorUrl }}
              </p>
            </a>
          </div>
        </div>
      </td>

      <td class="px-4">
        <div>
          <p v-if="item?.isNew ?? false">
            <span class="font-bold">{{ item.moreInfringementsCount }}</span> potential infringements
          </p>
          <p v-else>
            We identified <span class="font-bold">{{ item.moreInfringementsCount }}</span> potential <br />
            infringements
          </p>
        </div>
      </td>
      <td class="px-4 whitespace-pre-wrap">
        <p>{{ item.keywords.join(', ') }}</p>
      </td>
      <td class="px-4">
        <div class="w-65 space-y-1">
          <p class="text-sm text-bb-brand-purple font-medium truncate">{{ item.title }}</p>
          <p
            class="text-xs whitespace-pre-wrap"
            style="line-height: 14px"
          >
            {{ item.truncatedDescription }}
          </p>
        </div>
      </td>

      <td class="w-30 text-right px-6 text-xs">
        <p class="pNum">{{ item.dateLastSeen }}</p>
        <p class="pNum">{{ item.timeLastSeen }}</p>
      </td>
      <td class="w-30 pr-6">
        <div class="flex items-center justify-end gap-4 text-bb-disabled-buttons">
          <VMenu
            instant-move
            :distance="8"
            placement="bottom-start"
            theme="sm"
            :disabled="item.resolved"
          >
            <div @click.stop.prevent="resolveInfringement(item)">
              <ic-checked
                :size="20"
                class="cursor-pointer"
                :class="{ 'text-success': item.resolved }"
              />
            </div>
            <template #popper>
              <div v-close-popper>Mark as solved</div>
            </template>
          </VMenu>

          <VMenu
            instant-move
            :distance="8"
            placement="bottom-start"
            theme="sm"
          >
            <div @click.stop.prevent="whitelistInfringement(item)">
              <ic-flag
                :size="23"
                class="cursor-pointer"
                :class="{ 'text-bb-brand-purple': item.whitelisted }"
              />
            </div>
            <template #popper>
              <div v-close-popper>Add to Whitelist</div>
            </template>
          </VMenu>
        </div>
      </td>
    </tr>
  </tbody>
</template>

<script>
import IcChecked from 'vue-material-design-icons/CheckCircleOutline'
import IcFlag from '@/components/icon/brightbid/ic-flag.vue'
import dayjs from 'dayjs'

export default {
  name: 'InfringementTableRows',
  components: { IcChecked, IcFlag },
  props: {
    data: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      hoveredRow: null,
    }
  },
  computed: {
    tableRows() {
      const rows = structuredClone(this.data)

      return rows.map(item => {
        item.dateLastSeen = dayjs(item.lastSeenAt).format('MM/DD/YYYY')
        item.timeLastSeen = dayjs(item.lastSeenAt).format('HH:mm:ss')
        item.truncatedDescription =
          item.description.length > 124 ? `${item.description.slice(0, 124)}...` : item.description
        return item
      })
    },
  },
  methods: {
    resolveInfringement(infringement) {
      if (infringement.resolved) return
      this.$emit('infringement-action', {
        action: 'mark-all-as-solved',
        competitorName: infringement.competitorName,
        competitorUrl: infringement.competitorUrl,
      })
    },
    whitelistInfringement(infringement) {
      this.$emit('infringement-action', {
        action: 'whitelist',
        competitorName: infringement.competitorName,
        competitorUrl: infringement.competitorUrl,
      })
    },
  },
}
</script>
<style scoped lang="scss">
.gradient-border {
  border-left: 2px solid;
  border-image-source: linear-gradient(180deg, #f23f44 0%, #ff9900 100%);
  border-image-slice: 1;
}
.h-90px {
  height: 90px;
}
</style>
