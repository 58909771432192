import { render, staticRenderFns } from "./InfringementSummaryRows.vue?vue&type=template&id=43604caa&scoped=true"
import script from "./InfringementSummaryRows.vue?vue&type=script&lang=js"
export * from "./InfringementSummaryRows.vue?vue&type=script&lang=js"
import style0 from "./InfringementSummaryRows.vue?vue&type=style&index=0&id=43604caa&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "43604caa",
  null
  
)

export default component.exports