<template>
  <!--TABLE-->
  <div class="w-full px-6">
    <div class="border rounded-lg overflow-hidden">
      <div class="max-h-94 overflow-y-auto base-scrollbar">
        <table class="w-full">
          <table-header
            :headers="headers"
            :sorting-order="sortingOrder"
            class="sticky top-0 bg-white z-10"
            @sort-table="sortTable"
          />
          <tbody>
            <tr v-if="whitelist.length === 0">
              <td colspan="4">
                <div class="mx-auto my-20 flex flex-col items-center justify-center">
                  <p class="text-xl my-2">No whitelisted URLs found...</p>
                </div>
              </td>
            </tr>

            <tr
              v-for="(item, index) in sortedTableData"
              v-else
              :key="item.id"
              class="whitespace-no-wrap h-11 font-normal w-full text-sm"
              :class="[
                hoveredRow === index && !touchedWhitelistUrl[item.id] ? 'bg-bb-background-purple-0' : 'bg-white',
                index % 2 === 0 ? 'bg-white' : 'bg-neutral-0',
                touchedWhitelistUrl[item.id] && touchedWhitelistUrl[item.id]?.action === 'remove' ? 'bg-red-100' : '',
                touchedWhitelistUrl[item.id] && touchedWhitelistUrl[item.id]?.action === 'edit'
                  ? 'bg-bb-purple-10'
                  : '',
              ]"
              @mouseenter="hoveredRow = index"
              @mouseleave="hoveredRow = null"
            >
              <td
                class="px-4 py-2 whitespace-no-wrap sticky left-0"
                :class="[
                  hoveredRow === index && !touchedWhitelistUrl[item.id] ? 'bg-bb-background-purple-0' : 'bg-white',
                  index % 2 === 0 ? 'bg-white' : 'bg-neutral-0',
                  touchedWhitelistUrl[item.id] && touchedWhitelistUrl[item.id]?.action === 'remove' ? 'bg-red-100' : '',
                  touchedWhitelistUrl[item.id] && touchedWhitelistUrl[item.id]?.action === 'edit'
                    ? 'bg-bb-purple-10'
                    : '',
                ]"
              >
                <p v-if="selectedWhitelist?.id !== item.id">{{ touchedWhitelistUrl[item.id]?.url ?? item.url }}</p>
                <input
                  v-else
                  v-model="selectedWhitelist.url"
                  class="bg-transparent w-full border-2 border-bb-brand-purple rounded-md px-2 focus:outline-none"
                  @input="invalidWhitelistUpdate = false"
                />
              </td>
              <td class="w-50">
                <div class="flex gap-6 justify-end items-center pr-6">
                  <p
                    v-if="invalidWhitelistUpdate && selectedWhitelist?.id === item.id"
                    class="text-bb-red-error text-xs"
                  >
                    URL already exists
                  </p>
                  <ellipsis-loader
                    v-if="touchedWhitelistUrl[item.id]?.action === 'edit' && touchedWhitelistUrl[item.id]"
                    class="text-bb-brand-purple"
                  />
                  <ic-check
                    v-if="selectedWhitelist?.id === item.id && touchedWhitelistUrl[item.id]?.action !== 'edit'"
                    :size="18"
                    class="cursor-pointer text-success"
                    @click="editWhitelist"
                  />
                  <ic-cross
                    v-if="selectedWhitelist?.id === item.id && touchedWhitelistUrl[item.id]?.action !== 'edit'"
                    :size="20"
                    class="cursor-pointer text-bb-red-error hover:text-bb-red-error"
                    @close="
                      selectedWhitelist = null
                      invalidWhitelistUpdate = false
                    "
                  />

                  <ic-edit
                    v-if="selectedWhitelist?.id !== item.id && hoveredRow === index && !touchedWhitelistUrl[item.id]"
                    :size="20"
                    :class="
                      touchedWhitelistUrl[item.id]
                        ? ' pointer-events-none'
                        : 'text-bb-disabled-buttons hover:text-bb-brand-purple cursor-pointer'
                    "
                    @click="selectWhitelist(item)"
                  />
                  <ic-negative
                    :size="20"
                    class="cursor-pointer"
                    :class="
                      touchedWhitelistUrl[item.id]
                        ? 'text-bb-disabled-buttons pointer-events-none'
                        : 'text-bb-red-error'
                    "
                    @click="removeWhitelist(item)"
                  />
                </div>
              </td>
            </tr>
            <tr class="bg-neutral-50 h-14 sticky bottom-0">
              <td colspan="4">
                <div class="flex justify-between items-center pl-6 pr-2">
                  <div class="flex items-center gap-6 w-full">
                    <ic-plus
                      :size="24"
                      class="text-bb-brand-purple cursor-pointer"
                      @click="addWhitelist"
                    />
                    <input
                      v-if="!isAddingInfringement"
                      v-model="whitelistInput"
                      class="bg-transparent w-full border-0 focus:outline-none custom-placeholder font-medium"
                      type="text"
                      placeholder="Type URL ..."
                      @keydown.enter="addWhitelist"
                      @input="
                        hasDuplicateWhitelist = false
                        invalidWhitelist = false
                      "
                    />
                    <ellipsis-loader
                      v-if="isAddingInfringement"
                      class="text-bb-brand-purple"
                    />
                  </div>
                  <div>
                    <p
                      v-if="!hasDuplicateWhitelist && !invalidWhitelist"
                      class="text-bb-neutral-gray text-xs cursor-default w-32"
                    >
                      Press Enter to save
                    </p>
                    <p
                      v-if="hasDuplicateWhitelist"
                      class="text-bb-red-error text-xs cursor-default w-48"
                    >
                      This whitelist already exists!
                    </p>
                    <p
                      v-if="invalidWhitelist"
                      class="text-bb-red-error text-xs cursor-default w-32"
                    >
                      Please input a valid url.
                    </p>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import TableHeader from '@/components/table/TableHeader.vue'
import IcNegative from '@/components/icon/brightbid/ic-negative.vue'
import IcEdit from '@/components/icon/brightbid/ic-edit.vue'
import EllipsisLoader from '@/components/loader/EllipsisLoader.vue'
import IcCheck from '@/components/icon/ic-check.vue'
import IcCross from '@/components/icon/ic-cross.vue'
import { url } from 'vuelidate/lib/validators'
import sortingModule from '@/utils/table/table-sorting'

const defaultSortingOrder = { order: 'ASCENDING', column: null }

export default {
  name: 'WhitelistTable',
  components: { EllipsisLoader, TableHeader, IcNegative, IcEdit, IcCheck, IcCross },
  props: {
    whitelist: {
      type: Array,
      required: true,
    },
    isAddingInfringement: {
      type: Boolean,
      default: false,
    },
    touchedWhitelistUrl: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      headers: [
        {
          value: 'url',
          label: 'Website URL',
          sortable: true,
          sorting: null,
          position: 'left',
        },

        {
          value: 'action',
          label: 'Action',
          sortable: false,
          sorting: null,
          position: 'right',
        },
      ],
      sortingOrder: defaultSortingOrder,
      sortedTableData: [],
      isLoading: false,
      hoveredRow: null,
      whitelistInput: '',
      hasDuplicateWhitelist: false,
      invalidWhitelist: false,
      invalidWhitelistUpdate: false,
      selectedWhitelist: null,
    }
  },
  validations: {
    whitelistInput: { url },
  },
  watch: {
    whitelist: {
      immediate: true,
      handler(newVal) {
        this.sortedTableData = structuredClone(newVal)
      },
    },
  },
  methods: {
    sortTable(data) {
      // Sorting Process
      const sortedData = sortingModule(data, this.sortedTableData)

      // Set sorted data
      this.sortedTableData = sortedData.data
      // Set sorting order
      this.sortingOrder = sortedData.order
      // Set sorting order in headers
      this.headers.find(header => header.value === sortedData.order.column).sorting = sortedData.order.order
    },
    addWhitelist() {
      this.$v.whitelistInput.$touch()
      if (this.$v.$invalid) {
        this.invalidWhitelist = true
        return
      }

      if (this.whitelistInput.trim() === '') {
        this.clearInput()
        return
      }

      const invalidUrl = this.whitelist.find(item => item.url === this.whitelistInput.trim())

      if (!invalidUrl) {
        this.$emit('add-whitelist', this.whitelistInput)
        this.clearInput()
      } else {
        this.hasDuplicateWhitelist = true
      }
    },
    removeWhitelist(whitelist) {
      this.$emit('remove-whitelist', whitelist)
      this.selectedWhitelist = null
    },
    selectWhitelist(whitelist) {
      whitelist.originalUrl = whitelist.url
      this.selectedWhitelist = structuredClone(whitelist)
    },
    editWhitelist() {
      this.invalidWhitelistUpdate = false

      // check if there is a duplicate
      const duplicate = this.whitelist.find(
        item => item.url === this.selectedWhitelist.url && item.id !== this.selectedWhitelist.id,
      )

      if (duplicate) {
        this.invalidWhitelistUpdate = true
        return
      }

      // check if there is a change
      if (this.selectedWhitelist.url === this.selectedWhitelist.originalUrl) {
        this.selectedWhitelist = null
        return
      }
      this.$emit('edit-whitelist', this.selectedWhitelist)
      this.selectedWhitelist = null
    },
    clearInput() {
      this.whitelistInput = ''
      this.hasDuplicateWhitelist = false
    },
  },
}
</script>

<style scoped>
/* WebKit browsers (Arc, Chrome, Safari, etc.) */
.custom-placeholder::-webkit-input-placeholder {
  font-weight: 500;
  color: #3e4552;
}

/* Firefox */
.custom-placeholder::-moz-placeholder {
  font-weight: 500;
  color: #3e4552;
}

/* Internet Explorer 10-11 (Not needed for Arc) */
.custom-placeholder:-ms-input-placeholder {
  font-weight: 500;
  color: #3e4552;
}

/* Edge (Not needed for Arc) */
.custom-placeholder::-ms-input-placeholder {
  font-weight: 500;
  color: #3e4552;
}
</style>
