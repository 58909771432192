<template>
  <div :class="inlineLabel ? 'flex items-center gap-2' : 'space-y-2'">
    <slot name="label">
      <div
        v-if="label"
        class="flex items-center gap-2"
      >
        <slot name="left-icon"></slot>
        <p :class="labelStyle">{{ label }}</p>
        <slot name="right-icon"></slot>
      </div>
    </slot>

    <div
      ref="chipsContainer"
      :class="inlineChips ? 'flex items-center gap-2' : ''"
      class="w-full whitespace-no-wrap relative cursor-default"
    >
      <div
        v-for="(chip, index) in chips"
        :key="index"
        ref="chip"
      >
        <div
          :class="index >= chipCount && chips.length !== 1 ? 'opacity-0' : 'opacity-100'"
          class="flex items-center"
        >
          <div
            :class="[
              chipStyle,
              chip?.background ? chip.background : 'bg-neutral-0',
              chip?.color ? chip.color : 'text-neutral-900',
            ]"
          >
            <p
              class="truncate"
              :style="maxWidth ? `max-width: ${maxWidth}px` : 'max-width: 100%'"
            >
              {{ chip.label }}
            </p>
          </div>
        </div>
      </div>

      <VMenu
        v-if="chips.length - chipCount > 0 && chips.length !== 1"
        instant-move
        :distance="8"
        placement="bottom-start"
        theme="sm"
        class="absolute"
        :style="`left: ${hiddenCount}px`"
      >
        <div class="cursor-pointer">
          <p :class="showMoreButtonStyle">+{{ chips.length - chipCount }}</p>
        </div>

        <template #popper>
          <div v-close-popper>
            <div class="space-y-1">
              <div
                v-for="(chip, index) in chips.slice(chipCount, chips.length)"
                :key="index"
                class="flex items-center"
                :class="[
                  chipStyle,
                  chip?.background ? chip.background : 'bg-neutral-0',
                  chip?.color ? chip.color : 'text-neutral-900',
                ]"
              >
                {{ chip.label }}
              </div>
            </div>
          </div>
        </template>
      </VMenu>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ChipsCollection',
  props: {
    label: {
      type: String,
      default: '',
    },
    labelStyle: {
      type: String,
      default: 'text-sm font-medium text-bb-text-secondary',
    },
    inlineLabel: {
      type: Boolean,
      default: false,
    },
    inlineChips: {
      type: Boolean,
      default: true,
    },
    chips: {
      type: Array,
      required: true,
    },
    chipStyle: {
      type: String,
      default: 'rounded-full py-1 px-3',
    },
    showMoreButtonStyle: {
      type: String,
      default:
        'h-6 w-6 flex items-center justify-center bg-purple-0 rounded-sm text-bb-brand-purple font-bold hover:text-white hover:bg-bb-brand-purple duration-200 ease-linear',
    },
    showMoreButtonText: {
      type: String,
      default: '',
    },
    maxWidth: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      chipCount: 0,
      chipsWidths: [],
      width: 0,
    }
  },
  computed: {
    hiddenCount() {
      const availableChips = structuredClone(this.chipsWidths).slice(0, this.chipCount)
      let output = 0
      availableChips.forEach(chip => {
        output += chip + 10
      })

      return output
    },
  },
  mounted() {
    // Set the initial width
    this.updateDivWidth()

    this.$nextTick(() => {
      this.updateDivWidth()
    })

    // Add event listener for window resize
    window.addEventListener('resize', this.updateDivWidth)
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.updateDivWidth)
  },
  methods: {
    updateDivWidth() {
      this.chipCount = 0
      this.chipsWidths = []
      const chipsContainerWidth = this.$refs.chipsContainer.clientWidth
      const chipsRef = this.$refs.chip
      const gap = 8

      let limitReached = false
      let chipsWidthsSum = 0

      if (chipsRef) {
        chipsRef.map(child => {
          this.chipsWidths.push(child.offsetWidth)
        })
      }

      this.chipsWidths.forEach(item => {
        if (chipsWidthsSum < chipsContainerWidth - 50) {
          const widthAndGap = item + gap
          const _ = chipsWidthsSum + widthAndGap
          if (_ > chipsContainerWidth - 50) limitReached = true

          if (limitReached) return
          chipsWidthsSum += widthAndGap
          this.chipCount++
        }
      })
    },
  },
}
</script>

<style scoped lang="scss"></style>
