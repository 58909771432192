var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:_vm.inlineLabel ? 'flex items-center gap-2' : 'space-y-2'},[_vm._t("label",function(){return [(_vm.label)?_c('div',{staticClass:"flex items-center gap-2"},[_vm._t("left-icon"),_c('p',{class:_vm.labelStyle},[_vm._v(_vm._s(_vm.label))]),_vm._t("right-icon")],2):_vm._e()]}),_c('div',{ref:"chipsContainer",staticClass:"w-full whitespace-no-wrap relative cursor-default",class:_vm.inlineChips ? 'flex items-center gap-2' : ''},[_vm._l((_vm.chips),function(chip,index){return _c('div',{key:index,ref:"chip",refInFor:true},[_c('div',{staticClass:"flex items-center",class:index >= _vm.chipCount && _vm.chips.length !== 1 ? 'opacity-0' : 'opacity-100'},[_c('div',{class:[
            _vm.chipStyle,
            chip?.background ? chip.background : 'bg-neutral-0',
            chip?.color ? chip.color : 'text-neutral-900',
          ]},[_c('p',{staticClass:"truncate",style:(_vm.maxWidth ? `max-width: ${_vm.maxWidth}px` : 'max-width: 100%')},[_vm._v(" "+_vm._s(chip.label)+" ")])])])])}),(_vm.chips.length - _vm.chipCount > 0 && _vm.chips.length !== 1)?_c('VMenu',{staticClass:"absolute",style:(`left: ${_vm.hiddenCount}px`),attrs:{"instant-move":"","distance":8,"placement":"bottom-start","theme":"sm"},scopedSlots:_vm._u([{key:"popper",fn:function(){return [_c('div',{directives:[{name:"close-popper",rawName:"v-close-popper"}]},[_c('div',{staticClass:"space-y-1"},_vm._l((_vm.chips.slice(_vm.chipCount, _vm.chips.length)),function(chip,index){return _c('div',{key:index,staticClass:"flex items-center",class:[
                _vm.chipStyle,
                chip?.background ? chip.background : 'bg-neutral-0',
                chip?.color ? chip.color : 'text-neutral-900',
              ]},[_vm._v(" "+_vm._s(chip.label)+" ")])}),0)])]},proxy:true}],null,false,455691057)},[_c('div',{staticClass:"cursor-pointer"},[_c('p',{class:_vm.showMoreButtonStyle},[_vm._v("+"+_vm._s(_vm.chips.length - _vm.chipCount))])])]):_vm._e()],2)],2)
}
var staticRenderFns = []

export { render, staticRenderFns }