<template>
  <div class="flex items-center justify-between">
    <div class="flex items-center gap-2">
      <p class="text-base font-medium">All</p>

      <div
        class="bg-neutral-50 text-sm text-bb-text-secondary font-bold h-6 px-3 rounded-full flex items-center justify-center"
      >
        <p>{{ infringementCount }}</p>
      </div>
    </div>
    <div>
      <a
        href="https://support.google.com/legal/troubleshooter/1114905?_gl=1*emgne7*_ga*MTAyMDAwOTg4My4xNzE5NTgzNzA5*_ga_V9K47ZG8NP*MTcyMTIxNTk1Mi4yMi4wLjE3MjEyMTU5NTIuNjAuMC4w#ts=2981967%2C2981971%2C12981969%2C13037070"
        target="_blank"
      >
        <div class="flex items-center gap-3 bg-bb-text-default hover:bg-bb-brand-purple py-2 px-5 rounded-full">
          <p class="text-white">File a complaint to Google</p>
          <ic-open-new-window
            class="text-white"
            :size="12"
          />
        </div>
      </a>
    </div>
  </div>
</template>

<script>
import IcOpenNewWindow from '@/components/icon/brightbid/ic-open-new-window.vue'

export default {
  name: 'AllInfringementsTab',
  components: { IcOpenNewWindow },
  props: {
    infringementCount: {
      type: Number,
      default: 0,
    },
  },
}
</script>

<style scoped lang="scss"></style>
